import React, { useState } from "react"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"
import ticker from "@components/pageHome/products/images/logoTicker.svg"
import score from "@components/pageHome/products/images/logoScore.svg"
import platcon from "@components/pageHome/products/images/logoPlatcon.svg"
import imageTicker from "@components/pageHome/products/images/imageTicker.webp"
import imageScore from "@components/pageHome/products/images/imageScore.webp"
import imagePlatcon from "@components/pageHome/products/images/imagePlatcon.webp"
import capturaPlatcon from "@components/pageHome/products/images/capturePlatcon.webp"
import capturaTicker from "@components/pageHome/products/images/captureTicker.webp"
import capturaScore from "@components/pageHome/products/images/captureScore.webp"

const Ticker = {
  id: "1",
  product: "Conversations",
  text:
    "Nuestra plataforma omnicanal para servicio al cliente que integra canales como voz, WhatsApp, redes sociales y más. Crea chatbots y workflows potenciados con IA para notificaciones de compras, seguimiento de pedidos, confirmación de citas y más.",
  logo: ticker,
  img: imageTicker,
  captura: capturaTicker,
  link: "/conversations/",
  textlink: "Conoce más",
}
const Score = {
  id: "2",
  product: "Contact Center",
  captura: capturaScore,
  text:
    "Nuestro software en la nube para Contact Center potenciado con IA. Administra tus campañas entrantes y salientes con canales de voz y digitales. Usa marcaciones telefónicas, módulos de supervisión, workflows, IVR, analítica y mucho más.",
  logo: score,
  img: imageScore,
  link: "/contact-center/",
  textlink: "Conoce más",
}
const Platcon = {
  id: "3",
  product: "Validation",
  captura: capturaPlatcon,
  text:
    "Nuestra plataforma de contactabilidad que valida teléfonos con el mayor porcentaje de éxito, gracias a nuestros algoritmos y análisis en la frecuencia del sonido. Llama o contacta solo a números que realmente estén disponibles.",
  logo: platcon,
  img: imagePlatcon,
  link: "/validation/",
  textlink: "Conoce más",
}

const data = [
  {
    id: "1",
    product: "Conversations",
    text:
      "Nuestra plataforma omnicanal que brinda una experiencia única en servicio al cliente. Integra canales como voz, WhatsApp, redes sociales y más. Automatiza tus acciones creando chatbots y workflows potenciados con IA para notificaciones de compras, seguimiento de pedidos, confirmación de citas y mucho más.",
    logo: ticker,
    img: imageTicker,
    captura: capturaTicker,
    link: "/conversations/",
    textlink: "Conoce más",
  },
  {
    id: "2",
    product: "Contact Center",
    captura: capturaScore,
    text:
      "Nuestro software en la nube para Contact Center potenciado con IA. Administra tus campañas entrantes y salientes con canales de voz y digitales. Usa marcaciones telefónicas inteligentes para mejorar tu contactabilidad, así como módulos de supervisión, workflows, IVR, analítica y mucho más.",
    logo: score,
    img: imageScore,
    link: "/contact-center/",
    textlink: "Conoce más",
  },
  {
    id: "3",
    product: "Validation",
    captura: capturaPlatcon,
    text:
      "Nuestra plataforma de contactabilidad en la nube que valida teléfonos con el mayor porcentaje de éxito posible, gracias a nuestros algoritmos de tiempos por operador y análisis en la frecuencia del sonido. Llama o contacta solo a números que realmente estén disponibles.",
    logo: platcon,
    img: imagePlatcon,
    link: "/validation/",
    textlink: "Conoce más",
  },
]

const Card = ({ elemento, location, view }) => {
  return (
    <div className="container-products-cards-item-info">
      <div>
        {view ? (
          <div className="container-products-cards-item-info-viewInfo">
            <div>
              <img
                className="container-products-cards-item-info-viewInfo-logo"
                src={elemento.logo}
                alt="logo"
              />
              <p className="container-products-cards-item-info-viewInfo-text">
                {elemento.text}
              </p>
            </div>
            <img
              className="container-products-cards-item-info-viewInfo-capture"
              src={elemento.captura}
              alt=""
            />
          </div>
        ) : (
          <div className="container-products-cards-item-info-images">
            <img
              className="container-products-cards-item-info-images-logo"
              src={elemento.logo}
              alt="logo"
            />
            <img
              className="container-products-cards-item-info-images-image"
              src={elemento.img}
              loading="lazy"
              alt="..."
            />
          </div>
        )}
      </div>
      <Link
        to={`${elemento.link}${location.search}`}
        target="_blank"
        rel="noopener noreferrer"
        className="container-products-cards-item-info-button"
      >
        <span>{elemento.textlink}</span>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z"
            fill="#5831D8"
          />
        </svg>
      </Link>
    </div>
  )
}

const CardMobile = ({ elemento, location }) => {
  return (
    <div className="container-products-cards-item">
      <div className="container-products-cards-item-info">
        <div
        className="container-products-cards-item-info-images"
        >
          <img
            className="container-products-cards-item-info-images-logo"
            src={elemento.logo}
            alt="logo"
          />
          <img
            style={{ width: "30%" }}
            className="container-products-cards-item-info-images-image"
            src={elemento.img}
            alt="..."
          />
        </div>
          <Link
            to={`${elemento.link}${location.search}`}
            target="_blank"
            rel="noopener noreferrer"
            className="container-products-cards-item-info-button"
          >
            <span>{elemento.textlink}</span>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z"
              
              />
            </svg>
          </Link>
      </div>
    </div>
  )
}

const Products = ({ location }) => {
  const [warningTicker, setWarningTicker] = useState(false)
  const onHoverTicker = () => {
    setWarningTicker(true)
    setWarningScore(false)
    setWarningPlatcon(false)
  }

  const [warningScore, setWarningScore] = useState(false)
  const onHoverScore = () => {
    setWarningScore(true)
    setWarningTicker(false)
    setWarningPlatcon(false)
  }
  const [warningPlatcon, setWarningPlatcon] = useState(false)
  const onHoverPlatcon = () => {
    setWarningPlatcon(true)
    setWarningTicker(false)
    setWarningScore(false)
  }

  return (
    <section className="container-products">
      <p className="container-products-title">
        Beex tiene lo mejor en software para Contact Center y{" "}
        <TitleUnderline underline="tecnología omnicanal" />
      </p>
      <div className="container-products-cards container-products-cards-desktop">
        <div
          className={`${
            warningTicker ? "container-products-cards-item-active" : ""
          } 
          ${
            warningScore || warningPlatcon
              ? "container-products-cards-item-inactive"
              : ""
          }
          container-products-cards-item container-products-cards-item-1`}
          onMouseEnter={() => onHoverTicker()}
          onMouseLeave={() => setWarningTicker(false)}
        >
          <Card elemento={Ticker} location={location} view={warningTicker} />
        </div>
        <div
          className={` ${
            warningScore ? "container-products-cards-item-active" : ""
          } 
          ${
            warningTicker || warningPlatcon
              ? "container-products-cards-item-inactive"
              : ""
          }
          container-products-cards-item container-products-cards-item-2`}
          onMouseEnter={() => onHoverScore()}
          onMouseLeave={() => setWarningScore(false)}
        >
          <Card elemento={Score} location={location} view={warningScore} />
        </div>
        <div
          className={` ${
            warningPlatcon ? "container-products-cards-item-active" : ""
          } 
          ${
            warningScore || warningTicker
              ? "container-products-cards-item-inactive"
              : ""
          }
          container-products-cards-item container-products-cards-item-3`}
          onMouseEnter={() => onHoverPlatcon()}
          onMouseLeave={() => setWarningPlatcon(false)}
        >
          <Card elemento={Platcon} location={location} view={warningPlatcon} />
        </div>
      </div>
      <div className="container-products-cards container-products-cards-mobile">
        {data.map((ele, i) => {
          return(
          <div
            className={`container-products-cards-item container-products-cards-item-${i+ 1}`}
            key={i}
          >
            <CardMobile key={i} elemento={ele} location={location} />
          </div>
          )
        })}
      </div>
    </section>
  )
}
export default Products

import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      width="141"
      height="13"
      viewBox="0 0 141 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2007 11.3504C22.859 10.409 2.06955 13.1417 0.725195 12.9942C-0.599954 12.6851 0.110627 5.28783 1.10929 4.11466C1.46458 3.70019 1.93509 3.58077 3.06819 3.67912C3.78838 3.74234 21.6395 2.14767 27.449 1.69105C27.7755 1.9018 28.5917 1.09393 28.7838 1.36088C28.9278 1.51543 31.338 1.4522 34.9102 1.29765C53.0878 0.517883 54.5954 0.314159 69.3161 0.0472107C71.227 0.0120859 69.7194 0.461683 71.9663 0.300109C76.7484 -0.051139 87.1768 0.454658 90.8065 0.166634C93.7257 -0.0651893 94.3499 -0.0792387 97.1154 0.279034C99.0839 0.531933 115.034 0.974506 116.839 0.595158C117.271 0.482758 117.588 0.553008 117.578 0.714582C117.79 0.742682 128.18 1.55758 128.218 1.71213C128.276 1.88073 128.592 1.97908 128.909 1.88775C129.486 1.72618 140.855 2.07743 140.97 2.56215C141.297 3.95309 138.867 9.51686 137.398 10.8095C135.862 12.1653 129.092 9.91728 122.965 10.402C94.6764 8.68791 95.1661 9.14454 90.0287 9.49579C89.3758 8.98999 86.7927 10.121 85.2563 9.24991C84.6129 8.88461 79.9845 8.75816 78.957 9.10941C78.7362 9.17264 77.0941 9.17966 74.7127 9.14454C72.3121 9.11644 54.1441 8.82841 52.0123 9.79084C47.0478 9.03214 30.9828 10.8446 26.2007 11.3504Z"
        fill="#5831D8"
      />
    </svg>
  </span>
)

export default titleUnderline

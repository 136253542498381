import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="138" height="12" viewBox="0 0 138 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.6564 12C22.8033 10.886 1.91227 12.1297 0.393784 11.794C-0.845915 11.3438 1.13523 3.41627 2.28596 2.24888C2.6893 1.82923 3.1579 1.76821 4.23151 1.95896C5.93387 2.31757 27.9459 1.60797 27.9459 1.60797C28.2366 1.85213 29.1085 1.02809 29.2687 1.32566C29.7729 2.01999 65.4513 1.07387 68.4942 1.03572C70.3389 1.01283 68.8797 1.48588 71.0507 1.29514C75.6773 0.913637 85.761 1.27988 89.2665 0.84497C92.0722 0.486362 92.6772 0.471113 95.3701 0.761052C98.1224 1.02047 112.756 0.646599 114.428 0.0896111C114.838 -0.0629882 115.152 -0.00957376 115.152 0.165915C115.152 0.204065 125.467 0.33377 125.514 0.471109C125.591 0.646599 125.906 0.730523 126.197 0.608443C126.736 0.425324 137.716 -0.238485 137.888 0.272723C138.427 1.73768 136.915 7.97899 135.64 9.49735C134.335 11.1225 127.169 9.29898 121.41 10.2756L94.5931 10.3748L88.8454 10.9852C88.187 10.4587 85.6542 11.7863 84.1239 10.8402C83.4773 10.4664 78.91 10.4129 77.9016 10.8097C77.0238 11.1149 53.6593 10.3443 51.263 11.4048C46.9922 10.5198 29.672 11.7864 25.6564 12Z" fill="#FFBA00" />
    </svg>
  </span>
)

export default titleUnderline

import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      width="566"
      height="12"
      viewBox="0 0 566 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105.228 12C93.5264 10.886 7.84307 12.1297 1.61509 11.794C-3.46948 11.3438 4.65609 3.41627 9.37573 2.24888C11.03 1.82923 12.952 1.76821 17.3553 1.95896C24.3375 2.31757 114.619 1.60797 114.619 1.60797C115.811 1.85213 119.387 1.02809 120.044 1.32566C122.112 2.01999 268.445 1.07387 280.925 1.03572C288.491 1.01283 282.507 1.48588 291.411 1.29514C310.387 0.913637 351.744 1.27988 366.122 0.84497C377.629 0.486362 380.111 0.471113 391.156 0.761052C402.444 1.02047 462.461 0.646599 469.322 0.0896112C471 -0.0629882 472.29 -0.00957376 472.29 0.165915C472.29 0.204065 514.596 0.33377 514.791 0.471109C515.107 0.646599 516.397 0.730523 517.589 0.608443C519.802 0.425324 564.834 -0.238485 565.539 0.272723C567.753 1.73768 561.549 7.97899 556.319 9.49735C550.967 11.1225 521.578 9.29898 497.956 10.2756L387.969 10.3748L364.395 10.9852C361.694 10.4587 351.306 11.7863 345.03 10.8402C342.378 10.4664 323.645 10.4129 319.51 10.8097C315.909 11.1149 220.081 10.3443 210.252 11.4048C192.736 10.5198 121.698 11.7864 105.228 12Z"
        fill="#FFA700"
      />
    </svg>
  </span>
)

export default titleUnderline

import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="273"
      height="10"
      viewBox="0 0 273 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.6329 8.72465C44.3212 7.97829 3.2899 10.1013 0.938967 9.99622C-1.55094 9.75296 1.46011 3.89821 3.61417 2.96941C4.9344 2.4055 4.84175 2.81461 16.2258 2.4055C16.2258 2.4055 33.3888 1.80842 54.4893 1.11182C54.4893 1.11182 88.3868 0.210661 135.058 0.000575624C138.729 -0.0160101 135.822 0.332289 140.142 0.210661C149.349 -0.0381242 169.384 0.431804 176.356 0.232775C181.949 0.072447 183.154 0.0613902 188.493 0.365461C192.164 0.575547 222.969 1.09523 226.386 0.780103C227.219 0.702703 227.822 0.75246 227.81 0.879617C228.053 0.890674 248.239 1.64809 248.331 1.76419C248.47 1.89687 249.084 1.9798 249.675 1.91346C250.763 1.7863 272.594 2.17883 272.883 2.56583C273.717 3.67154 269.907 8.03911 267.232 9.05083C264.464 10.0957 251.041 8.26025 239.183 8.57538C183.872 6.94445 184.3 7.35909 175.059 7.57471C173.785 7.17112 168.759 8.03911 165.771 7.34803C164.52 7.05502 155.533 6.94445 153.518 7.19324C153.09 7.24852 149.882 7.23746 145.249 7.19876C142.933 7.18218 140.258 7.15453 137.397 7.13242C133.576 7.09925 104.658 6.94998 101.033 7.59129C91.2821 6.94998 59.9092 8.33765 50.6329 8.72465Z"
        fill="#FFBA00"
      />
    </svg>
  </span>
)

export default titleUnderline
